import React, { useRef, useState } from 'react';
import './HomePage.css';
import WaitlistForm from '../components/WaitlistForm';

// Team images
import sohamImage from '../assets/soham.jpg';
import nickImage from '../assets/nick.jpg';
import pranaiImage from '../assets/pranai.jpg';
import darkLogo from '../assets/MorphLogoBlack.jpg'

interface TeamMember {
  name: string;
  title: string;
  bio: string;
  longBio: string;
  image: string;
  linkedin: string;
}

const teamMembers: TeamMember[] = [
  {
    name: "Pranai Reddy",
    title: "CEO",
    bio: "Pranai is a business management student at Rice University with experience in venture capital and the healthcare industry.",
    image: pranaiImage,
    linkedin: "https://www.linkedin.com/in/pranaireddy/",
    longBio: "Pranai's journey began in high school when he co-founded a nonprofit providing prosthetics to underserved amputees, igniting his passion for bioengineering and healthcare innovation. Initially studying bioengineering at Rice University, he transitioned to business and biochemistry to focus on scaling transformative ideas. He founded and fundraised for the Rice Venture Fund, a $2M student-led investment fund. He gained deep venture capital expertise at VU Venture Partners and Soma Capital ($1B AUM), where he built a global network of founders and investors. Now leading Morph Labs' commercialization strategy, he drives fundraising, market adoption, and strategic partnerships, ensuring the company's breakthrough prosthetic reaches those who need it most."
  },
  {
    name: "Nick Cadavid",
    title: "CPO",
    bio: "Nick is a mechanical engineer student at FSU with experience building exoskeletons and a passion for creating advanced prosthetics.",
    image: nickImage,
    linkedin: "https://www.linkedin.com/in/nickcadavid/",
    longBio: "A senior Mechanical Engineering student at FSU, who founded Morph Labs after developing a prototype of a highly dexterous prosthetic hand. His background in research, advanced prototyping, and mechanical design makes him the driving force behind Morph's product innovation. With research experience at the National High Magnetic Field Laboratory—where he worked on cutting-edge projects such as dark matter detection—Nick brings a deep technical foundation and relentless pursuit of innovation to the company."
  },
  {
    name: "Soham Mehra",
    title: "CTO",
    bio: "Soham is an industrial and systems engineering student at Virginia Tech with experience in AI/ML in the mortgage and healthcare industries.",
    image: sohamImage,
    linkedin: "https://www.linkedin.com/in/soham-mehra/",
    longBio: "As an engineering student at Virginia Tech, he specializes in developing machine learning models for healthcare applications. His experience ranges from building AI-driven cloud infrastructure at ICE Mortgage Technology to developing a deep learning-powered mobile app for maternal health monitoring for biomedical research initiatives. At NuvoAir Medical, a Series A startup, he designed and deployed AI solutions to enhance patient outcomes. At Morph Labs, Soham leads AI/ML model development, EEG signal processing, and software architecture, ensuring seamless brain-to-device interaction. His expertise in AI-driven healthcare innovation positions Morph at the forefront of non-invasive prosthetic control."
  }
];

const HomePage: React.FC = () => {
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);
  const missionRef = useRef<HTMLElement>(null);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleWaitlistOpen = () => {
    setIsWaitlistOpen(true);
  };

  const handleWaitlistClose = () => {
    setIsWaitlistOpen(false);
  };

  const handleMemberClick = (member: TeamMember) => {
    setSelectedMember(member);
  };

  const handleModalClose = () => {
    setSelectedMember(null);
  };

  return (
    <div className={`home-page-wrapper ${selectedMember ? 'blurred' : ''}`}>
      <div className="home-page">
        <section className="hero">
          <div className="content">
            <h1 className="logo"><img src={darkLogo} alt="Morph Logo" /></h1>
            <p>We're building world class prosthetics powered by brainwaves, transforming neural signals into new possibilities.</p>
            <div className="button-group">
              <button onClick={() => scrollToSection('mission')} className="cta-button">Our Mission</button>
              <button onClick={() => scrollToSection('team')} className="cta-button">Our Team</button>
            </div>
            <button onClick={handleWaitlistOpen} className="cta-button meet-button">Meet with Us</button>
          </div>
        </section>
        <section className="info-section" ref={missionRef} id='info-section'>
          <div className="info-subsection mission" id="mission">
            <h1>Our Mission</h1>
            <div className="mission-content">
              <div className="mission-box">
                <h3>Unmatched Dexterity</h3>
                <p>With 19 degrees of freedom our hand allows for precise movements restoring functionality that allows to complete the same tasks you can with a natural hand.</p>
              </div>
              <div className="mission-box">
                <h3>Over 44% of Upper Limb Amputees stop using their prosthetic due to muscle fatigue and poor functionality.</h3>
              </div>
              <div className="mission-box">
                <h3>Current Bionic Prosthics have 3-10 degrees of freedom. That's less than half of the human hand, which has 27.</h3>                
              </div>
              <div className="mission-box">
                <h3>Intuitive, Non-Invasive Control</h3>
                <p>Our EEG-based (BCI) harnesses the user's phantom limb signals, enabling natural, intuitive control -- all without surgical implants or any sort of muscle fatigue.</p>
              </div>
            </div>
          </div>
          <div className="info-subsection team" id="team">
            <h2>Our Team</h2>
            <p>Meet the dedicated team behind Morph, committed to innovation and excellence in AI-powered prosthetics.</p>
            <div className='team-members'>
              {teamMembers.map((member, index) => (
                <div key={index} className="team-member">
                  <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="member-image-link">
                    <img src={member.image} alt={member.name} className="member-image" />
                  </a>
                  <div className="member-info" onClick={() => handleMemberClick(member)}>
                    <h3>{member.name}</h3>
                    <p>{member.title}</p>
                    <p className="member-bio">{member.bio}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="footer-links">
            <a href="#mission" onClick={() => scrollToSection('mission')} className="footer-link">Our Mission</a>
            <a href="#team" onClick={() => scrollToSection('team')} className="footer-link">Our Team</a>
            <a href="#contact" onClick={handleWaitlistOpen} className="footer-link">Meet with Us</a>
          </div>
          <p>&copy; 2024 Morph. All rights reserved.</p>
          <a href="https://www.linkedin.com/company/morph-labs-ai" target="_blank" rel="noopener noreferrer" className="footer-link">
            LinkedIn
          </a>
        </footer>
      </div>
      {isWaitlistOpen && <WaitlistForm onClose={handleWaitlistClose} />}
      {selectedMember && (
        <div className="modal-overlay" onClick={handleModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{selectedMember.name}</h2>
            <p>{selectedMember.longBio}</p>
            <button className="close-button" onClick={handleModalClose}>×</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;